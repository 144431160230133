.overview__card_latency {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 2%;
    padding-bottom: 2%;
    margin-top: 20px;
    height: auto;
    overflow-wrap: anywhere;
    .overview__title_latency {
        background-color: transparent;
        padding-bottom: "0";
        border-bottom: 1px solid rgb(234, 234, 234);
        height: 25px;
        line-height: 25px;
        font-size: 14px !important;
    }
    .overview__mani_latency {
        background-color: transparent;
        display: inline-flex;
    }
    .seriesDesc {
        width: 980px;
        display: flex;
        flex-wrap: wrap;
        margin-right: auto;
        .desc {
            display: flex;
            align-items: center;
            i {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-left: 10px;
                margin-right: 5px;
            }
            span {
                font-size: 14px;
            }
        }
    }
    .overview__detail__latency {
        margin-top: 20px;
        Label {
            margin-left: 5px;
            margin-bottom: 8px;
        }
    }
    .drawingContainer {
        background-color: transparent;
    }
}

.drawingBoard {
    margin-top: 1px;
}

.editXIcon {
    margin-left: auto;
    margin-right: 1%;
}

.editYIcon {
    margin-right: auto;
    margin-left: 2%;
}

.latency_dropdown {
    margin-left: auto;
    margin-right: 10px;
}

.dropdown_item {
    width: 200px;
    margin-right: 10px;
}
