.ms-ChoiceFieldLabel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 400px;
}

.expPicker {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.expPic {
    height: 40px;
    width: 100%;
    border-bottom: #dedede solid 1px;
    display: inline-flex;
    flex-direction: row;
    margin-right: 10%;
    position: sticky;
    top: 0;
    z-index: 999;
    background: transparent;
}
