@function compactRowHeight() {
    @return 24px;
}

.titleBar {
    justify-content: space-between;
}

.filterContainer {
    padding-left: 4px;
    padding-right: 13px;
    margin-bottom: 10px;
}

.compactList {
    .ms-List-cell {
        min-height: compactRowHeight();

        .ms-DetailsRow-cell {
            height: compactRowHeight();
            min-height: compactRowHeight();

            span {
                margin-top: 2px;
            }
        }
    }
}
