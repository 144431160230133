.selectcount {
    font-size: 20px;
    height: 40px;
}

.multipleSelection .ms-Viewport {
    margin: 0;
    width: 100%;
}

.multipleSelection {
    max-height: 265px;
    margin-bottom: 10px;
}
.tooltip {
    word-wrap: break-word;
    white-space: normal;
    display: flex;
    width: 350px;
    flex-flow: wrap;
}
.ms-DetailsRow-cell[data-automation-key="delete"] {
    padding-right: 0px;
    width: 40px;
}
.ms-DetailsRow-cell[data-automation-key="order"] {
    padding-left: 0px;
}
