.analysisVal {
    display: inline-block;
    width: 50px;
}

.diffStyle {
    display: inline-block;
    width: 70px;
    font-weight: bold;
}
