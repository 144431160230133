@import "~@fluentui/react/dist/sass/References";
.websiteTitle {
    font-weight: 600;
    font-size: "18px";
    .iconButton {
        &:hover {
            background-color: transparent;
        }
    }
}

.ContextualMenuStyle {
    width: 200;
}

.release__title {
    height: 100%;
    line-height: 30px;
    font-size: 15px !important;
}

.release__content {
    height: 100%;
    margin: 0 0 0 16px;
    font-size: 12px;
}

.contentStyles {
    display: flex;
    flex-flow: column nowrap;
    height: 80%;
    width: 80%;
    align-items: stretch;
}

.contentHeader {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 12px 12px 14px 24px;
}

.contentBody {
    flex: 4 4 auto;
    padding: 0 24px 24px 24px;
    overflow-y: auto;
}

.iconButton {
    margin-left: auto;
    margin-top: 4px;
    margin-right: 2px;
}

.headerStack {
    height: 48px;
    font-size: 16px;
    font-family: Helvetica;
    min-height: 20;
    display: flex;
    justify-content: flex-start;
}

.microsoftLogo {
    width: 126px;
    display: block;
    margin-right: 5px;
}

.userAliasText {
    margin-left: 0 !important;
    margin-right: 5px;
    color: white;
    font-size: 14px;
}
