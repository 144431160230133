@import "~@fluentui/react/dist/sass/References";
.itemkey {
    height: 35px;
    line-height: 35px;
    display: inline-flex;
    width: 100%;
    margin-top: 6px;
    .itemkey__text {
        overflow: hidden;
    }
    .itemkey__copy {
        float: right;
        display: none;
    }
    &:hover > .itemkey__copy {
        display: block;
    }
    &:visited {
        color: #75b6e7;
    }
}

.table-header {
    display: inline-flex;
    margin: 9px 9px 9px 0px;
    .table-header__button {
        position: relative;
    }
    .filterToggle {
        margin-left: 10px;
        margin-bottom: 0;
        label {
            font-weight: 700;
        }
    }
}

:hover > .link_item {
    color: #75b6e7;
}

:focus-within > .link_item {
    color: #75b6e7;
}

.table-content__text {
    height: 18px;
    display: block;
    font-family: Helvetica;
}

$lineHeight: 35px;
.table-list {
    line-height: $lineHeight;
    // ::-webkit-scrollbar {
    //     width: 12px;
    //     height: 12px;
    // }
    // ::-webkit-scrollbar-thumb {
    //     border: 4px solid transparent;
    //     box-shadow: none;
    //     background: rgba(189, 189, 189, 0.8);
    //     background-clip: padding-box;
    // }

    // ::-webkit-scrollbar-thumb:hover {
    //     background-color: red;
    // }

    // ::-webkit-scrollbar-track {
    //     box-shadow: none;
    // }
    .strValBox {
        padding: 0 10px 0 0;

        .stringNowrap {
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 60px;
            min-height: $lineHeight;
        }

        .stringNormal {
            min-height: $lineHeight;
        }
    }
}

.ms-ScrollablePane {
    height: 100%;
}

.see_drak {
    background-color: grey;
}
.see {
    background-color: #dad6d6;
}
