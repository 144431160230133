.documentcard {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 2%;
    padding-bottom: 2%;
    margin-top: 20px;
    overflow-wrap: anywhere;
    .overview_lineChart_title {
        background-color: transparent;
        padding-bottom: "0";
        border-bottom: 1px solid rgb(234, 234, 234);
        height: 25px;
        line-height: 25px;
        font-size: 14px !important;
        font-weight: bolder;
    }
    .overview_detail {
        background-color: transparent;
        display: inline-flex;
    }
    .seriesDesc {
        width: auto;
        display: flex;
        flex-wrap: wrap;
        margin-right: auto;
        .desc {
            display: flex;
            align-items: center;
            i {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-left: 10px;
                margin-right: 5px;
            }
            span {
                font-size: 14px;
            }
        }
    }
    .drawingContainer {
        background-color: transparent;
        .drawingBoard {
            margin-top: 1px;
            .nameParagraph {
                margin: 0;
                padding: 0;
                text-align: right;
                transform: rotate(180deg);
            }
        }
    }
}

.tip {
    position: absolute;
    border: 1px solid steelblue;
    padding: 5px;
    color: yellow;
}
