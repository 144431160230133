@import "~@fluentui/react/dist/sass/References";
.title {
    padding: 1%;
    margin-top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    .search-box {
        padding-right: 120px;
        width: 100%;
        background-color: #f3f2f1;
        border: 1px solid #f3f2f1;
        border-bottom: 1px solid #f3f2f1;
    }
    .ms-Dropdown-title {
        border: none;
        background-color: #f3f2f1;
        height: 28px;
        margin-top: 3px;
    }
    .dropDown {
        position: absolute;
        top: -1px;
        right: 30px;
    }
    .icon {
        position: absolute;
        top: 1px;
        right: 2px;
        height: 30px;
    }
    .tip {
        margin-top: 5px;
        font-size: 12px;
        color: #0078d4;
    }
    .pageLabel {
        font-size: 20px;
        margin-top: 10px;
    }
    .createBtn {
        width: 170px;
    }
}

.tile {
    position: relative;
    .button {
        margin-top: 10px;
        position: relative;
    }
}

.ms-Viewport {
    box-shadow: $ms-depth-shadow-4;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
}

.tagContainer {
    vertical-align: top;
    display: inline-block;
    text-align: center;
}

.calloutSize {
    max-width: 600;
}

.callout_header {
    padding: 18px 24px 12px;
}

.callout_inner {
    height: 100%;
    padding: 0 10px 20px;
}

.tagCallout {
    color: #389e0d;
    background-color: #f6ffed;
    font-size: 12px;
    border: 1px #b7eb8f solid;
    margin: 0 6px 0 4px;
    padding: 0 7px;
}

.searchStyle {
    border: 1px black solid;
    border-radius: 12px;
}

.ms-ScrollablePane {
    height: 100%;
}

.itemId {
    display: inline-flex;
    width: 100%;
    .itemId__text {
        overflow: hidden;
    }
    .itemId__copy {
        float: right;
        display: none;
    }
    &:hover > .itemId__copy {
        display: contents;
    }
}
