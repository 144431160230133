.ms-TextField-fieldGroup {
    width: 100%;
    .ms-TextField-suffix {
        display: none;
    }
    &:hover>.ms-TextField-suffix {
        display: inline-flex;
    }
}

.ms-Dropdown-item.is-selected {
    background-color: rgba(var(--palette-primary, 0, 120, 212), .1)
}

.label-root {
    display: block;
    margin: 0px;
    padding: 5px;
    box-sizing: border-box;
    box-shadow: none;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    overflow-wrap: break-word;
}

.fieldGroup {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    width: auto;
    height: 32px;
    margin: 0px;
    padding: 0px;
    border: none;
    background: transparent;
    cursor: default;
}

.record_tag_eval {
    background-color: transparent;
    border: 1px solid rgb(122, 122, 122);
    font-size: 14px;
    padding: 5px;
    width: 119px;
    height: 19px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.record_tag_more_eval {
    background-color: transparent;
    border: 1px solid rgb(122, 122, 122);
    font-size: 14px;
    margin-right: 1px;
    padding: 5px;
    width: 85px;
    height: 19px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.more_tag_eval {
    background-color: #cfcecd;
    border: 1px solid rgb(122, 122, 122);
    border-radius: 0;
    font-size: 11px;
    padding: 5px;
    width: 33px;
    height: 31px;
    text-align: center;
    color: #605e5c;
     :hover {
        color: #0078d4;
    }
}

.tagAdd_eval {
    background-color: #cfcecd;
    border: 1px solid rgb(122, 122, 122);
    border-radius: 0;
    font-size: 11px;
    margin-left: 1px !important;
    padding: 5px;
    width: 33px;
    height: 31px;
    text-align: center;
    color: #605e5c;
     :hover {
        color: #0078d4;
    }
}