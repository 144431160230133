.mainContainer {
    width: 800px;
    height: 300px;
    padding: 10px;

    .formCol {
        width: 300px;

        .formInput {
            border: 1px solid rgb(138, 136, 134);

            .ms-TextField-fieldGroup {
                width: 298px;
            }
        }

        .timeRangePicker {
            width: 145px;
            border: 1px solid rgb(138, 136, 134);

            .ms-TextField-fieldGroup {
                width: 142px;
            }
        }
    }
    .btnLayout {
        padding-top: 20px;
        padding-right: 60px;

        button {
            width: 85px;
        }
    }
}
