.fullScreen {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .expandIcon {
        visibility: hidden;
    }
    &:hover {
        .expandIcon {
            visibility: visible;
        }
    }
}
