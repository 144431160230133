.ms-DocumentCard {
    max-width: 100%;
}

.ms-Viewport {
    width: 100%;
    // ::-webkit-scrollbar {
    //     width: 10px;
    //     height: 6px;
    //     background-color: #f5f5f5;
    // }
    // ::-webkit-scrollbar-track {
    //     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    //     border-radius: 10px;
    //     background-color: #f5f5f5;
    // }
    // ::-webkit-scrollbar-thumb {
    //     border-radius: 10px;
    //     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    //     background-color: #bdbdbd;
    // }
    // ::-webkit-scrollbar-thumb:hover {
    //     border-radius: 5px;
    //     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    //     background: rgba(0, 0, 0, 0.4);
    // }
}

// ::-webkit-scrollbar {
//     width: 2px;
//     height: 6px;
//     background-color: #f5f5f5;
// }

// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
//     border-radius: 10px;
//     background-color: #f5f5f5;
// }

// ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
//     background-color: #bdbdbd;
// }

// ::-webkit-scrollbar-thumb:hover {
//     border-radius: 5px;
//     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
//     background: rgba(0, 0, 0, 0.4);
// }

.ms-Pivot-link {
    &:hover {
        background-color: rgba(218, 217, 216, 0.87);
    }
}

.entityList {
    margin-left: 10px;
    width: 98.5%;
    .ms-Pivot-link {
        height: 30px;
        width: 21%;
        margin: 2px 2px 2px 2px;
        justify-content: space-around;
        text-align: center;
        &:hover {
            background-color: #ffffff;
            color: rgb(0, 120, 212);
            font-weight: bold;
        }
        &:focus {
            background-color: #ffffff;
            color: rgb(0, 120, 212);
            font-weight: bold;
        }
    }
}

.overview__card_latency {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 2%;
    padding-bottom: 2%;
    margin-top: 20px;
    height: auto;
    overflow-wrap: anywhere;
}

.ms-Overlay {
    background-color: rgba(255, 255, 255, 0.1);
}
.tick line {
    stroke: #c8c8c8;
}
.displayFlex {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
