@import "LineChartWithXScaleBand.scss";

.tip {
    position: absolute;
    border: 1px solid steelblue;
    padding: 5px;

    .tipText {
        border: none;
        filter: grayscale(1) contrast(999) invert(1);

        td {
            border: none;
        }
    }
}
