@import "~@fluentui/react/dist/sass/References";
.catalogContainer {
    width: 95%;
    height: 67.7%;
}

.modal {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    width: 80%;
    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    .refresh {
        margin-left: 8px;
    }
    .modal__imagecontainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2%;
    }
    .modal__image {
        max-width: 90%;
    }
    .header {
        padding-bottom: 20;
    }
    .modal__title {
        flex: 1 1 auto;
        border-top: 4px solid $ms-color-themePrimary;
        color: $ms-color-neutralPrimary;
        display: flex;
        align-items: center;
        font-weight: bolder;
        padding: 12px 12px 14px 24px;
    }
}

.overview {
    overflow: hidden;
    height: 100%;
    .overview__card {
        max-width: 100%;
        min-width: 100%;
        margin-bottom: 2%;
        padding-bottom: 2%;
        margin-top: 20px;
        height: auto;
        overflow-wrap: anywhere;
        .overview__title {
            padding-bottom: "0";
            border-bottom: 1px solid rgb(234, 234, 234);
            height: 25px;
            line-height: 25px;
            font-size: 14px !important;
        }
    }
    .overview__detail {
        margin-top: 20px;
    }
}

@mixin tableCell {
    display: inline-flex;
    align-items: center;
    margin-top: 6px;
    min-width: 85px;
    max-width: 110px;
}

.table__item__flex {
    flex: 1;
    @include tableCell;
}

.table__item {
    @include tableCell;
}

.table__item_highlight {
    @include tableCell;
    font-weight: 800;

    * {
        font-weight: 800;
    }
}

.widerCell {
    width: 105px;
}

.text_highlight {
    font-weight: 800;
    width: 70px;
    @include tableCell;
    * {
        font-weight: 800;
    }
}

.release_tile {
    margin: 1%;
    position: relative;
}

.verticalStyle {
    height: 100%;
}

.ms-Viewport {
    box-shadow: $ms-depth-shadow-4;
    .ms-DocumentCardTitle {
        font-size: 20px;
        font-weight: bold;
    }
    .release__title {
        height: 100%;
        line-height: 30px;
        font-size: 15px !important;
    }
    .release__content {
        height: 100%;
        margin: 0 0 0 16px;
        font-size: 20px;
    }
    .release__date {
        font-size: 12px;
        color: grey;
        margin: 0 0 5px 16px;
    }
}

.viewOptions {
    margin-right: 8px;
    .ms-ChoiceFieldGroup-flexContainer {
        display: inline-flex;
        margin-left: 2px;
    }
}

.ms-ChoiceField {
    margin-left: 10px;
}

.linkIsSelected {
    background-color: white;
}
button[class~="ms-Pivot-link"][role="tab"].is-selected {
    border-bottom: solid 2px rgb(0, 120, 212);
    &::before {
        height: 0px;
    }
}
button[class~="ms-Pivot-link"][role="tab"] {
    border-bottom: solid 0px rgb(0, 120, 212);
    &::before {
        height: 0px;
    }
}

.settingIcon {
    &:hover {
        background-color: transparent;
    }
}

.toolbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    .toolbar-item {
        display: flex;
        height: 100%;
    }
}

.expand .ms-Viewport {
    margin-top: 0px;
}
