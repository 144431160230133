@mixin cleanBorder {
    border: none;
    outline: none;
}

@mixin grayBackground {
    background-color: transparent;
    border: 1px solid transparent;
}

.ms-Dropdown-item.is-selected {
    background-color: rgba(var(--palette-primary, 0, 120, 212), 0.1);
}

.filterBox {
    display: flex;
    padding: 8px;
    @include grayBackground;
    .searchInput {
        width: 100%;
        @include cleanBorder;
        @include grayBackground;
    }
    .dropdownCtrl {
        max-width: 300px;
        .ms-Dropdown-title {
            background-color: transparent;
            font-weight: bold;
            @include cleanBorder;
        }
        .ms-Dropdown-titleIsPlaceHolder {
            font-weight: normal;
        }
    }

    .dropdownCtrl2 {
        flex: 1;
        max-width: 400px;
        .ms-Dropdown-title {
            background-color: transparent;
            font-weight: bold;
            @include cleanBorder;
        }
        .ms-Dropdown-titleIsPlaceHolder {
            font-weight: normal;
        }
    }
}
