@import "~@fluentui/react/dist/sass/References";
.Resizer {
    position: relative;
    z-index: 1;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: content-box;
    /* background-color: $ms-color-neutralLight; */
}

.Resizer.vertical {
    width: 10px;
    height: 100%;
    cursor: col-resize;
    margin: 0px -4px;
}

.Resizer.vertical:after {
    content: "";
    display: block;
    width: 4px;
    height: 12px;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
}

.LeftPane-Banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.LeftPane-Banner-Title {
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    // justify-content: center;
    width: 80%;
    height: 30px;
    color: rgba(244, 244, 244, 1);
    /* background-color: rgba(244, 244, 244, 1) */
}

#file-upload {
    visibility: collapse;
    width: 0px;
    height: 0px;
}

// ::-webkit-scrollbar {
//     width: 4px;
//     height: 4px;
//     background-color: #f5f5f5;
// }

// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     border-radius: 10px;
//     background-color: #f5f5f5;
// }

// ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: #bdbdbd;
// }

// ::-webkit-scrollbar-thumb:hover {
//     border-radius: 5px;
//     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//     background: rgba(0, 0, 0, 0.4);
// }
