.navigation-container {
    display: flex;
    flex-direction: column;
}

.ms-Nav {
    border-right: 0px solid transparent
}

.nav_link {
    width: 133px;
    height: 30px;
    margin: 2px;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;
    &:hover {
        /* #dcdcdc */
        background-color: transparent;
    }
}

.nav_link_checked {
    width: 133px;
    height: 30px;
    margin: 2px 2px 2px 0px;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;
    border-left: 3px solid var(--communication-background, rgba(0, 120, 212, 1));
}

.nav_item {
    margin-top: 5px;
    margin-left: 10px;
}

.nav_image {
    width: 20px;
    img {
        height: 1.5rem;
        width: 1.5rem;
    }
}

.nav_title {
    color: #323130;
    font-weight: 400;
}

@mixin icon {
    background-clip: text;
    color: transparent;
}

.redIcon {
    background: #da3b01;
    @include icon;
}
.yellowIcon {
    background: #fce100;
    @include icon;
}
.greenIcon {
    background: #0b6a0b;
    @include icon;
}

.cyanIcon {
    background: #038387;
    @include icon;
}

.blueIcon {
    background: #4f6bed;
    @include icon;
}

.magentaIcon {
    background: #881798;
    @include icon;
}

.grayIcon {
    background: #e3008c;
    @include icon;
}



