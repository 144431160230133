.visualizerPanel {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .fileContainer {
        width: 100%;
        justify-content: space-around;
    }

    .pageBar {
        width: 100%;
        justify-content: space-around;
        margin: 30px auto;
    }

    .fileMetaContainer {
        width: 100%;
        justify-content: space-between;

        .fileMeta {
            width: 48%;
        }
    }

    .pagePrContainer {
        width: 100%;
        justify-content: space-between;

        margin-top: 30px;
        .pagePr {
            width: 48%;

            .prTable {
                width: 50%;
            }
        }
    }

    .tableTitle {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.loadFailed {
    padding-top: 100px;
    text-align: center;
}
