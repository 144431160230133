.titleEnd {
    margin-left: 65%;
}

.viewer_par {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.viewer {
    display: flex;
    height: 100%;
    .viewer__container {
        margin: 0 auto;
        position: relative;
    }
    .viewer__tooltip {
        position: absolute;
        color: #fff;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.8);
        transform: translate3d(-50%, -50%, 0);
        border-radius: 5px;
        pointer-events: none;
        z-index: 1000;
    }
    .viewer__setting {
        margin-left: 10px;
        .ms-Viewport {
            max-width: 1800px;
        }
    }
    .viewer__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
    }
}
