.ms-List-cell > div {
    &:hover .unfavorite {
        display: inline-flex !important;
        margin-top: 2px;
    }
    .favorite {
        display: inline-flex;
        margin-top: 2px;
    }
    .unfavorite {
        display: none;
    }
}

.tooltip_tag {
    font-size: 13px;
    padding: 5px;
    margin-right: 1%;
    margin-bottom: 2px;
    background-color: #edebe9;
    word-break: break-all;
}

.record_tag,
.record_tag_more {
    background-color: #edebe9;
    border: 1px solid #ffffff;
    font-size: 11px;
    margin-right: 2%;
    padding: 5px;
    border-radius: 5px;
    width: 85px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.more_tag {
    background-color: #edebe9;
    border: 1px solid #ffffff;
    font-size: 11px;
    margin-right: 2%;
    padding: 5px;
    border-radius: 5px;
    width: 25px;
    height: 27px;
    text-align: center;
    color: #605e5c;
    :hover {
        color: #0078d4;
    }
}

.tagItems {
    border: "1px soid #ffffff";
    :hover {
        background-color: #edebe9;
    }
}

.tagAdd {
    background-color: #edebe9;
    border-radius: 5px;
    padding: 5px;
    width: 25px;
    height: 27px;
    margin-left: 1px;
    color: #605e5c;
    :hover {
        color: #0078d4;
    }
}
