.framediv {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.iframediv iframe {
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: scale(0.8);
}
