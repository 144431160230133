@mixin containerLayout {
    height: 700px;
    width: 70%;
    background-position: center;
    background-repeat: no-repeat;
}

.viewer__container {
    width: 100%;
    .exhibitionArea {
        width: 100%;
        justify-content: space-between;

        .imageContainer {
            @include containerLayout;
            background-image: url("/sand-clock.png");
        }

        .emptyContainer {
            @include containerLayout;
            background-image: url("/photo-error.png");
        }

        .settingContainer {
            width: 30%;
            max-width: 30%;
        }
    }
    table {
        width: 90%;
        margin: 0 auto;

        th {
            width: 50%;

            label {
                font-size: 18px;
                font-weight: bold;
            }
        }

        td {
            width: 50%;
            padding-left: 20px;
            padding-right: 15px;
            vertical-align: top;

            label {
                word-break: break-all;
            }
        }
    }

    .emptyMetric {
        margin: 0 auto;
        font-size: 20px;
        font-weight: bolder;
    }
}
