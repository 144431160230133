@mixin CircularNavBtn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20%;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 50%;
    :hover {
        cursor: pointer;
    }
}

.barChartTip {
    padding: 5px;
    position: absolute;
    font-size: 18px;
    font-weight: bolder;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    border: none !important;
}

.drawingContainer {
    width: auto;
    height: auto;
    position: relative;
    .drawingBoard {
        overflow-x: auto;
        .leftBtn {
            left: 2px;
            @include CircularNavBtn;
        }
        .rightBtn {
            right: 2px;
            @include CircularNavBtn;
        }

        > svg > g > {
            rect:hover {
                fill: #dcbeff;
            }
        }
    }
    .seriesDesc {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .desc {
            display: flex;
            align-items: center;
            width: 100%;
            i {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-left: 10px;
                margin-right: 5px;
            }
            span {
                width: calc(100% - 30px);
                font-size: 14px;
            }
        }
    }
}
