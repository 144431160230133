@import "~@fluentui/react/dist/sass/References";
//modal
.modalContainer {
    width: 980px;
    height: 67.7%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    .header {
        flex: 1 1 auto;
        border-top: 4px solid $ms-color-themePrimary;
        border-bottom: 1px solid $ms-color-neutralLight;
        color: $ms-color-neutralLight;
        display: flex;
        align-items: center;
        font-weight: bolder;
        padding: 12px 12px 14px 24px;
        font-size: 20px;
    }
    .modalLabel {
        font-size: 18px;
        font-weight: bold;
    }
    .iconButton {
        color: $ms-color-neutralQuaternaryAlt;
        margin-left: auto;
        margin-top: 4px;
        margin-right: 2px;
         :hover {
            color: $ms-color-neutralQuaternaryAlt;
        }
    }
    .content {
        flex: 4 4 auto;
        padding: 2px 24px 24px 24px;
        overflow-y: hidden;
        padding-bottom: "70px";
        .contentBody {
            min-height: 280px;
            justify-content: center;
            align-items: center;
            display: flex;
            .image {
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .footer {
        width: 100%;
        position: relative;
        left: 0px;
        bottom: 0px;
        min-height: 24px;
        line-height: 24px;
        z-index: 999;
        border-top: 2px solid #eee;
        background-color: $ms-color-neutralLight;
        padding: 1%, 2%, 2%, 0;
    }
}

.detailsTitle {
    margin: 20px;
}