$filterCatWidth: 400px;

@mixin promptFont {
    font-family: "Segoe UI", "-apple-system, BlinkMacSystemFont, Roboto",
        "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.promptText {
    display: inline-block;
    padding-top: 30px;
    padding-left: 30px;
    @include promptFont();
}

.tlmOverview {
    .selectedTag {
        width: 100%;
    }

    .clearBtn {
        margin-left: 10px;
        width: 180px;

        i {
            font-size: 24px;
            margin-top: -8px;
        }
    }

    .searchBar {
        padding: 20px 0px 20px 0px;

        .filterCategory {
            width: $filterCatWidth;
        }

        .searchBox {
            width: calc(100% - #{$filterCatWidth});
            margin-left: 15px;
            background-color: #f3f2f1;
        }
    }

    .scrollableContainer {
        position: relative;

        .filterListPrompt {
            @include promptFont();
        }
    }

    .filterList {
        height: 250px;
    }

    .statList {
        height: 690px;
    }
}
